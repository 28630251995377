import { RefreshOutlined } from '@mui/icons-material';
import { IconButton, Stack } from '@mui/material';
import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { ControlledDatePicker, ControlledSelect, FormGrid, Options, OptionsToggle } from '../../../shared';
import { FilterType, IRunReportFilter, IRunReportDateFilter, IRunReportListFilter, IReportFilter } from '../../models';
import { allRequiredFiltersFilledInCheck } from '../../utils/all-required-filters-filled-in-check.util';

interface Props {
    availableFilters: IReportFilter[];
    onChange: (filters: IRunReportFilter[]) => void;
    filters: IRunReportFilter[];
}

type DashboardFilterForm = Record<string, string[] | { startDate?: string; endDate?: string }>;

const DOT_CHARACTER = '__DOT__';

export const ReportFilter: FC<Props> = ({ availableFilters, filters, onChange }) => {
    const { t } = useTranslation();
    const form = useForm();
    const hasFilterFormChanged = useHasFilterFormChanged(form, filters);
    const areAllRequiredFiltersFilledIn = useHasAllRequiredFiltersFilledIn(form, availableFilters);

    const [options, setOptions] = useState<Options>({});

    const onFilterChange = useCallback(
        (formValues: DashboardFilterForm) => {
            onChange(mapFormValuesToAppliedFilters(formValues));
        },
        [onChange],
    );

    useEffect(() => {
        form.reset({});
        filters.forEach((filter) => {
            const fieldName = getFieldNameFromColumn(filter.column);
            if (filter.type === FilterType.DATE) {
                form.setValue(`${fieldName}.startDate`, filter.start);
                form.setValue(`${fieldName}.endDate`, filter.end);
            }
            if (filter.type === FilterType.SELECT) {
                form.setValue(fieldName, filter.values);
            }
        });
    }, [filters, form.setValue]);

    useEffect(() => {
        setOptions({
            filter: { type: 'title', label: t('filter') },
            ...mapAvailableFilterToFilterOptions(availableFilters),
        });
    }, [availableFilters]);

    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <OptionsToggle options={options} onChange={setOptions} />
            <FormGrid containerProps={{ spacing: 1, py: 1 }} sx={{ '&.MuiGrid-item': { pr: 1 } }}>
                {availableFilters.map((filter) => {
                    if (!options[filter.label]?.active && !filter.required) return <Fragment key={filter.label} />;

                    const fieldName = getFieldNameFromColumn(filter.column);
                    return (
                        <Fragment key={filter.label}>
                            {filter.type === FilterType.DATE && (
                                <Stack direction="row" spacing={1}>
                                    <ControlledDatePicker
                                        name={`${fieldName}.startDate`}
                                        label={t('startDate')}
                                        control={form.control}
                                        size="small"
                                    />
                                    <ControlledDatePicker
                                        name={`${fieldName}.endDate`}
                                        label={t('endDate')}
                                        control={form.control}
                                        size="small"
                                    />
                                </Stack>
                            )}
                            {filter.type === FilterType.SELECT && (
                                <ControlledSelect
                                    name={fieldName}
                                    label={filter.label}
                                    form={form}
                                    options={filter.values || []}
                                />
                            )}
                        </Fragment>
                    );
                })}
            </FormGrid>
            {hasFilterFormChanged && areAllRequiredFiltersFilledIn && (
                <IconButton onClick={form.handleSubmit(onFilterChange)}>
                    <RefreshOutlined color="primary" />
                </IconButton>
            )}
        </Stack>
    );
};

function useHasAllRequiredFiltersFilledIn(
    form: UseFormReturn<DashboardFilterForm>,
    availableFilters: IReportFilter[],
): boolean {
    const formValues = form.watch();

    return useMemo(() => {
        const filters = mapFormValuesToAppliedFilters(formValues);

        return allRequiredFiltersFilledInCheck(availableFilters, filters);
    }, [formValues, availableFilters]);
}

function useHasFilterFormChanged(
    form: UseFormReturn<DashboardFilterForm>,
    appliedFilters: IRunReportFilter[],
): boolean {
    const formValues = form.watch();

    return useMemo(() => {
        const newlyAppliedFilters = mapFormValuesToAppliedFilters(formValues);
        return JSON.stringify(newlyAppliedFilters) !== JSON.stringify(appliedFilters);
    }, [appliedFilters, formValues]);
}

function mapFormValuesToAppliedFilters(filterForm: DashboardFilterForm): IRunReportFilter[] {
    return Object.entries(filterForm)
        .map(([fieldName, filterValue]) => mapFilter(getColumnFromFieldName(fieldName), filterValue))
        .filter((appliedFilter) => !!appliedFilter) as IRunReportFilter[];
}

function mapFilter(
    column: string,
    value: string[] | { startDate?: string; endDate?: string },
): IRunReportDateFilter | IRunReportListFilter | undefined {
    if (isArrayValue(value)) return { type: FilterType.SELECT, column, values: value };
    if (isDateValue(value)) return { type: FilterType.DATE, column, start: value.startDate, end: value.endDate };
}

function isArrayValue(value: string[] | { startDate?: string; endDate?: string }): value is string[] {
    return Array.isArray(value) && value.length > 0;
}

function isDateValue(
    value: string[] | { startDate?: string; endDate?: string },
): value is { startDate: string; endDate: string } {
    const castedValue = value as { startDate?: string; endDate?: string };
    return Boolean(castedValue.startDate && castedValue.endDate);
}

function mapAvailableFilterToFilterOptions(availableFilters: IReportFilter[]) {
    return availableFilters
        .filter(({ required }) => !required)
        .reduce((filters, { label }) => ({ ...filters, [label]: { active: false, label: label } }), {});
}

function getFieldNameFromColumn(column: string): string {
    return column.replace('.', DOT_CHARACTER);
}

function getColumnFromFieldName(fieldName: string): string {
    return fieldName.replace(DOT_CHARACTER, '.');
}
