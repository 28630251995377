export enum FilterType {
    SELECT = 'SELECT',
    DATE = 'DATE',
}

export interface IReportFilter {
    type: FilterType;
    column: string;
    label: string;
    required?: boolean;
    values?: IReportSelectFilterValue[];
}

interface IReportSelectFilterValue {
    label: string;
    value: string;
}
