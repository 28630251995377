export enum ColumnType {
    DATE = 'DATE',
    TEXT = 'TEXT',
    NUMBER = 'NUMBER',
}

export interface IColumn {
    name: string;
    label: string;
    type: ColumnType;
    hidden: boolean;
}
