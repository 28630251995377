import { FilterType, IReportFilter, IRunReportFilter } from '../models';

export function allRequiredFiltersFilledInCheck(
    availableFilters: IReportFilter[],
    filters: IRunReportFilter[],
): boolean {
    return availableFilters
        .filter(({ required }) => required)
        .every((availableFilter) =>
            filters.some((filter) => {
                if (availableFilter.type === filter.type && availableFilter.column === filter.column) {
                    if (filter.type === FilterType.SELECT) return filter.values.length > 0;
                    if (filter.type === FilterType.DATE) return filter.start && filter.end;
                }
            }),
        );
}
